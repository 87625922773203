import React, { useRef } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'

import Spinner from '../Svg/Spinner'
import { useInitialDimensions } from '../Hooks'

export const Button = ({
  type = 'agent',
  subType = 'primary',
  variant = 'filled',
  size = 'medium',
  isLoading = false,
  isDisabled = false,
  submit = false,
  IconLeft,
  IconRight,
  className,
  customWidth,
  children,
  onClick,
  onMouseEnter,
  onMouseLeave
}) => {
  const buttonRef = useRef(null)
  const initialButtonDimensions = useInitialDimensions(buttonRef)
  const width = customWidth ?? `${initialButtonDimensions?.width}px`

  const buttonType = submit ? 'submit' : 'button'

  return (
    <button
      type={buttonType}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      ref={buttonRef}
      disabled={isDisabled || isLoading}
      className={cn(
        'ButtonV2',
        { isAgent: type === 'agent'},
        { isCustomer: type === 'customer'},
        `ButtonV2-${subType}--${variant}`,
        `ButtonV2-${size}`,
        { isDisabled: isDisabled || isLoading },
        className
      )}
      style={{ width }}
    >
      {isLoading ? (
        <Spinner className={cn('ButtonV2-spinner', `ButtonV2-spinner--${size}`)} />
      ) : (
        <div className='ButtonV2-content'>
          {IconLeft && <IconLeft className={cn('ButtonV2-iconWrapper', `ButtonV2-iconWrapper--${size}`)} />}
          <span
            className={cn(
              'ButtonV2-text',
              `ButtonV2-text-${subType}--${variant}`,
              `ButtonV2-text-${size}`
            )}
          >
            {children}
          </span>
          {IconRight && <IconRight className={cn('ButtonV2-iconWrapper', `ButtonV2-iconWrapper--${size}`)} />}
        </div>
      )}
    </button>
  )
}

Button.propTypes = {
  type: PropTypes.oneOf(['agent', 'customer']),
  subType: PropTypes.oneOf(['primary', 'black', 'danger']),
  variant: PropTypes.oneOf(['filled', 'outline', 'ghost']),
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  className: PropTypes.string,
  customWidth: PropTypes.string,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  submit: PropTypes.bool,
  IconLeft: PropTypes.node,
  IconRight: PropTypes.node,
  children: PropTypes.node,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func
}

export default Button
